<template>
  <div>
    <div class="align-items-center">
      <vue-horizontal-calendar
        :lang="'en'"
        :choosedDatePos="'center'"
        :choosedItemColor="'var(--blue)'"
        :todayItemColor="'#E0EAF8'"
        :showBorderTop="false"
        :swipeSpace="7"
      />
    </div>
    <div class="p-3 py-4">
      <div class="d-flex flex-column">
        <div class="bold-text-head">
          You have {{ visits.length > 0 ? visits.length : "no" }} visits
        </div>
        <div class="bold-text-head">scheduled for today</div>
      </div>
    </div>
    <div class="p-4 d-flex flex-column">
      <div class="visit" v-for="visit in visits" :key="visit.visit_id">
        <div
          class="
            d-flex
            flex-column
            align-items-between
            justify-content-between
            p-1
          "
        >
          <div class="d-flex">
            <div
              v-if="!visit.preauth.preauth_id"
              class="mr-1 shield-no-preauth d-flex"
              style="position: relative"
            >
              <b-icon icon="shield-minus" scale="1.4" />
              <div class="ml-2">No Preauth</div>
            </div>
            <div v-else class="mr-1 shield-approved" style="position: relative">
              <b-icon icon="shield" scale="1.4" />
            </div>
            <div class="d-flex flex-column">
              <div v-if="visit.preauth_cptcodes">
                <div
                  v-for="(cptCode, index) in visit.preauth_cptcodes"
                  :key="index"
                  class="d-flex"
                >
                  <div
                    style="
                      color: var(--dark);
                      font-size: 12px;
                      font-weight: 500;
                    "
                  >
                    {{ cptCode.code }}
                  </div>
                  <div style="color: var(--gray); font-size: 11px" class="ml-1">
                    - {{ cptCode.short }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="(icdCode, index) in visit.preauth_icdcodes"
                  :key="index"
                  class="d-flex"
                >
                  <div
                    style="
                      color: var(--dark);
                      font-size: 12px;
                      font-weight: 500;
                    "
                  >
                    {{ icdCode.code }}
                  </div>
                  <div style="color: var(--gray); font-size: 11px" class="ml-1">
                    - {{ icdCode.description }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column pl-2 my-2 justify-content-center">
            <div style="color: var(--dark); font-size: 12px">
              {{ visit.patient.full_name }}
            </div>
            <div style="color: var(--gray); font-size: 12px">
              Acct# {{ visit.patient.account_no || "N/A" }}
            </div>
            <div style="color: var(--gray); font-size: 12px">
              {{ visit.location_name }}
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b>{{ formatTime(visit.start_time) }} </b>
            <b v-if="visit.end_time" class="ml-1">
              - {{ formatTime(visit.end_time) }}</b
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatDate,
  formatDecimal,
  formatTime,
  debounce,
} from "../../common.js";
import VueHorizontalCalendar from "vue-horizontal-calendar";

export default {
  name: "ProviderSchedule",
  components: {
    VueHorizontalCalendar,
  },
  props: {
    provider: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      // visits/preauths stuff
      visits: [],
    };
  },

  methods: {
    formatTime,
    formatDecimal,
    formatDate,
    make_url,
    authenticated_request,
    debounce,
    getCalendarEvents() {
      var today = new Date();
      today = today.toISOString().split("T")[0];
      var params = {
        "visit_date:start_date": today + "T00:00:00.000Z",
        "visit_date:end_date": today + "T23:59:59.000Z",
      };
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      }

      const url = make_url("/reports/preauth");
      const vm = this;
      const promise = authenticated_request({
        method: "get",
        url: url,
        params: params,
      })
        .then(function (response) {
          if (response) {
            console.log(response.data);
            vm.visits = response.data.visits;
          }
        })
        .catch(function (error) {
          console.log(error.response);
          // vm.showError(error);
        })
        .finally(() => {});
      return promise;
    },
  },
  computed: {
    selected_provider_id() {
      if (this.provider) {
        return this.provider.provider_id;
      }
      return null;
    },
    user_practice_id: function () {
      return localStorage.getItem("practice_id");
    },
  },
  watch: {
    provider: function (newVal, oldVal) {
      this.getCalendarEvents();
    },
  },
  async mounted() {
    this.getCalendarEvents();
  },
};
</script>

<style scoped>
.right-side-schedule-container {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 20px;
}

/* calendar event styles */
.visit {
  color: var(--dark);
  box-shadow: 1px 1px 10px #16171814 !important;
  min-width: 150px;
  padding: 10px;
  margin-bottom: 24px;
  font-size: 12px;
  background-color: #f2f6fc;
  border-radius: 10px;
  border: 1px solid #c9d2df;
}
.bold-text-head {
  font-size: 28px;
  font-weight: bold;
  color: var(--dark);
  z-index: 1;
}
.shield-text {
  font-size: 9px;
  font-weight: 500;
  position: absolute;
  top: 1px;
  left: 25%;
}
.shield-no-preauth {
  color: grey;
}
.shield-approved {
  color: var(--pink);
}
.shield-pending {
  color: #fea12a;
}
.shield-denied {
  color: #f05c50;
}

/* horizontal calendar styles */
::v-deep .horizontal-calendar .date-item {
  border-radius: 25px;
  border-right: none;
}
::v-deep .horizontal-calendar .date-list {
  border-radius: 25px;
  border-right: none;
  width: calc(100% - 60px);
}
::v-deep .horizontal-calendar {
  max-width: 1200px;
  border-bottom: none;
}
::v-deep .horizontal-calendar .left-arrow,
::v-deep .horizontal-calendar .right-arrow {
  width: 30px;
  height: 100%;
  line-height: 50px;
  border-left: none;
  border-right: none;
  background-color: white;
}
</style>