
<template>
    <div class="greeting-card mb-5">
      <div class="blob-1-img"><img :src="require('@/assets/blob1.svg')" /></div>
      <div class="blob-2-img"><img :src="require('@/assets/blob2.svg')" /></div>
      <div class="provider-hero-img"><img :src="require('@/assets/provider-hero.svg')" /></div>
      <div class="d-flex flex-column justify-content-center h-100 ml-5 pl-4">
        <div v-if="provider" class="bold-text-head" >Hello, {{ provider.full_name_with_suffix || 'provider'}}!</div>
        <div v-else class="bold-text-head">No provider selected</div>
        <div v-if="provider" class="greeting-text-light mb-3">Here's what's happening so far this month</div>
        <div v-else class="greeting-text-light mb-3">To see a provider's billing totals for this month, please select a provider</div>
        <b-overlay :show="loading" variant="transparent">
            <div class="d-flex flex-row">
                <div class="flex-column mr-4">
                  <div class="greeting-label-text">Total billed</div>
                  <div class="stat-text-med">{{ formatDollars(quickSummaryData.totalBilled, false) }}</div>
                </div>
                <div class="flex-column mr-4">
                  <div class="greeting-label-text">Total encounters</div>
                  <div class="stat-text-med">{{ formatNumber(quickSummaryData.totalEncounters) }}</div>
                </div>
                <div class="flex-column mr-4">
                  <div class="greeting-label-text">Total paid</div>
                  <div class="stat-text-med">{{ formatDollars(quickSummaryData.totalPaid, false) }}</div>
                </div>
                <div class="flex-column">
                  <div class="greeting-label-text">Net Collection %</div>
                  <div class="stat-text-med">{{ formatPercent(quickSummaryData.totalPaid / quickSummaryData.totalBilled, false) }}</div>
                </div>
            </div>
        </b-overlay>
      </div>
    </div>
</template>

<script>
import { make_url, authenticated_request, permissionsMixin, formatNumber, formatPercent, formatDollars } from '../../../common.js'
       
export default {
    name: "ProviderGreetingWidget",
    mixins: [permissionsMixin],
    props: {
        provider: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            quickSummaryData: {
              totalEncounters: null,
              totalBilled: null,
              totalPaid: null,
            },
            loading: false,
        }
    },
    methods: {
        formatDollars,
        formatNumber,
        formatPercent,
        getQuickSummaryData(){
          this.loading = true;
          var params = {}
          if (this.provider?.provider_id) {
            params.provider_id = this.provider.provider_id;
          } else {
            this.loading = false;
            return
          }
          var vm = this;
          authenticated_request({
              method: "get",
              url: make_url("/reports/providers/quick_summary").toString(),
              params: params
          }).then(function(response){
              const items = response.data
              vm.quickSummaryData.totalEncounters = items.total_encounters;
              vm.quickSummaryData.totalBilled = items.total_billed;
              vm.quickSummaryData.totalPaid = items.total_paid;
          }).catch(function(error){
              console.log(error.response)
          }).finally(()=>{
              vm.loading = false;
          });
        },

    },
    watch: {
        provider: function(newVal, oldVal) {
          if (newVal) {
            this.getQuickSummaryData();
          } else {
            this.quickSummaryData = {
              totalEncounters: null,
              totalBilled: null,
              totalPaid: null,
            }
          }
        },
        practice: function(newVal, oldVal) {
          if (newVal) {
            this.getQuickSummaryData();
          }
        }
    },
    async mounted() {
        this.getQuickSummaryData();
    },
}
</script>

<style scoped>
/* greeting card with various static assets absoultely positioned */
.greeting-card {
  position:relative;
  background-color: #DDE4F5;
  height: 200px;
  width: 100%;
  border-radius: 15px;
}
.greeting-text-light {
  font-size: 20px;
  font-weight:300;
  color: var(--dark);
  z-index: 1;
}
.greeting-label-text {
  font-size: 14px;
  color: var(--dark);
  z-index: 1;
}
.stat-text-med {
  font-size: 28px;
  color: var(--dark);
  font-weight:500;
  z-index: 1;
}
.blob-1-img {
  position:absolute;
  top:0;
  left:0;
  z-index:0;
}
.blob-2-img {
  position:absolute;
  bottom:0;
  left:15px;
  z-index:0;
}
.provider-hero-img {
  position:absolute;
  bottom:0;
  right:0;
  z-index:0;
}
.provider-hero-img img{
  width:85%;
  height:auto;
  z-index:0;
}
.billing-summary-card {
  background-color: white;
  min-height: 375px;
  width: 100%;
  border-radius: 15px;
  font-size:12px;
  font-weight: bold;
}
</style>