<template>
  <job-overlay :job="job" :loading="billingSummaryLoading" @finished="onFinished">
    <div class="billing-summary-card shadow mb-4 p-4">
      <b-row>
        <b-col cols="4" class="d-flex flex-column justify-content">
          <div class="data-label" style="font-size: 14px">
            <b-row>
              <b-col>
              Total <strong>{{providerOrPractice}}</strong> billed value 
              </b-col>
              <b-col v-if="doAllowPracticeTotals">
                <!-- Drop down to switch between provider and practice totals -->
                <b-button-group>
                  <b-button :pressed="providerOrPractice == 'provider'" size="sm" class="ml-2 avo-basic-btn" @click="setProviderOrPracticeTotals('provider')">
                    Provider
                  </b-button>
                  <b-button :pressed="providerOrPractice == 'practice'" size="sm" class="ml-2 avo-basic-btn" @click="setProviderOrPracticeTotals('practice')">
                    Practice
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </div>
          <div style="color: #010c14; font-size: 36px; font-weight: bold">
            {{ formatDollars(totalBillingValue, false) }}
          </div>
          <div v-if="provider" 
            class="encounters-line"
            v-b-tooltip.hover.bottomleft="{ variant: 'dark' }"
            title = "Your Encounters / Total Practice Encounters">
            Encounters: {{ formatNumber(totalEncounters) }} 
          </div>
          <div class="data-label" style="font-size: 14px" v-if="!provider">
            Please select a provider to view billing summary
          </div>
          <div v-if="provider" class="d-flex flex-column justify-content mt-5 pr-3">
            <div class="d-flex flex-column mb-3">
              <div class="data-label">Total AR balance</div>
              <div class="d-flex justify-content-between mb-2">
                <div class="small-dollar-amount">
                  {{ formatDollars(totalBalance, false) }}
                </div>
                <div class="billing-percentage">
                  {{ getBillingBarPercentage(totalBalance) }}%
                </div>
              </div>
              <div style="width: 100%">
                <div class="thin-bar-background"></div>
                <div
                  class="thin-bar-overlay"
                  :style="getBillingBarStyle('balance')"
                ></div>
              </div>
            </div>
            <div class="d-flex flex-column mb-3">
              <div class="data-label">Total paid</div>
              <div class="d-flex justify-content-between mb-2">
                <div class="small-dollar-amount">
                  {{ formatDollars(totalPaid, false) }}
                </div>
                <div class="billing-percentage">
                  {{ getBillingBarPercentage(totalPaid) }}%
                </div>
              </div>
              <div style="width: 100%">
                <div class="thin-bar-background"></div>
                <div
                  class="thin-bar-overlay"
                  :style="getBillingBarStyle('paid')"
                ></div>
              </div>
            </div>
            <div class="d-flex flex-column mb-3">
              <div class="data-label">Total adjustments</div>
              <div class="d-flex justify-content-between mb-2">
                <div class="small-dollar-amount">
                  {{ formatDollars(totalAdjustments, false) }}
                </div>
                <div class="billing-percentage">
                  {{ getBillingBarPercentage(totalAdjustments) }}%
                </div>
              </div>
              <div style="width: 100%">
                <div class="thin-bar-background"></div>
                <div
                  class="thin-bar-overlay"
                  :style="getBillingBarStyle('adjustments')"
                ></div>
              </div>
            </div>
            <div class="d-flex flex-column mb-3">
              <div class="data-label">Total write-offs</div>
              <div class="d-flex justify-content-between mb-2">
                <div class="small-dollar-amount">
                  {{ formatDollars(totalWriteOffs, false) }}
                </div>
                <div class="billing-percentage">
                  {{ getBillingBarPercentage(totalWriteOffs) }}%
                </div>
              </div>
              <div style="width: 100%">
                <div class="thin-bar-background"></div>
                <div
                  class="thin-bar-overlay"
                  :style="getBillingBarStyle('write-offs')"
                ></div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="8" style="text-align:right">
          <b-button-group class="mb-2">
            <b-button :pressed="chartDateRange == 'year'" size="sm" class="ml-2 avo-basic-btn" @click="setChartDateRange('ytd')">
              YTD
            </b-button>
            <b-button :pressed="chartDateRange == 'quarter'" size="sm" class="ml-2 avo-basic-btn" @click="setChartDateRange('quarter')">
              Current Quarter
            </b-button>
            <b-button :pressed="chartDateRange == 'month'" size="sm" class="ml-2 avo-basic-btn" @click="setChartDateRange('month')">
              Last Month
            </b-button>
          </b-button-group>
          <div class="chart-container monthly-totals-chart">
            <monthly-totals-line-chart
              :chartData="monthlyTotalsChartData"
            ></monthly-totals-line-chart>
          </div>
        </b-col>
      </b-row>
    </div>
  </job-overlay>
</template>

<script>
import JobOverlay from "../../JobOverlay";
import MonthlyTotalsLineChart from "../../charts/portalCharts/MonthlyTotalsLineChart.vue";
import {
  make_url,
  authenticated_request,
  formatNumber,
  formatDollars,
  permissionsMixin
} from "../../../common";

export default {
  name: "BillingSummaryWidget",
  components: {
    MonthlyTotalsLineChart,
    JobOverlay,
  },
  mixins: [permissionsMixin],
  props: {
    provider: {
      type: Object,
      required: false,
    },
    practice: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      chartDateRange: "year",
      providerOrPractice: "provider",
      monthlyTotalsChartData: {
        labels: [],
        datasets: [
          {
            label: "Your billed value",
            data: [],
            backgroundColor: "transparent",
            borderColor: "#5579D1",
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
          },
          {
            label: "Provider billed avg.",
            data: [],
            backgroundColor: "transparent",
            borderColor: "#5579D1",
            borderDash: [15, 15],

            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
          },
        ],
      },
      billingSummaryLoading: false,
      providerData: null,
      practiceData: null,
      averageData: null,
      months: [
        "tilt",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      job: null,
    };
  },
  methods: {
    zeroHoursAndMinutes(d){
      d.setHours(0);
      d.setMinutes(0);
      d.setSeconds(0);
      d.setMilliseconds(0);
    },
    formatNumber,
    formatDollars,
    setProviderOrPracticeTotals(value) {
      this.providerOrPractice = value;
    },
    setChartDateRange(value) {
      this.chartDateRange = value;
    },
    onFinished() {
      this.getBillingSummaryData();
    },
    setBillingSummaryData(items) {
      const vm = this;
      vm.practiceData = items.practice;
      vm.providerData = items.provider;
      vm.averageData = items.average;

      // sort by months ascending, accounting for year
      var filteredChartData = items.billing_by_month;
      filteredChartData.sort(function (a, b) {
        var month_a = parseFloat(a.date_range.split("_")[0]),
          month_b = parseFloat(b.date_range.split("_")[0]),
          year_a = parseFloat(a.date_range.split("_")[1]),
          year_b = parseFloat(b.date_range.split("_")[1]);

        if (year_a == year_b) {
          return month_a - month_b;
        } else {
          return year_a - year_b;
        }
      });

      let providerMonthlyData = [],
        averageMonthlyData = [],
        oneYearAgoData = [],
        labels = [];

      function parse_date(item) {
        return {
          month: vm.months[Number(item.date_range.split("_")[0])],
          year: item.date_range.split("_")[1],
        };
      }
      filteredChartData.forEach(function (item) {
        // note: this reverses order to descending
        providerMonthlyData.push(item.provider_paid || 0);
        averageMonthlyData.push(item.average_paid || 0);
        oneYearAgoData.push(item.average_paid_one_year_ago || 0);
        const parsed_date = parse_date(item);
        labels.push(
          `${parsed_date.month} ${parsed_date.year}`
        );
      });

      // must replace the entire chartdata object as an object for Vue reactivity 
      vm.monthlyTotalsChartData = {
        labels: labels,
        datasets: [
          {
            label: "Your paid value",
            data: providerMonthlyData,
            backgroundColor: "transparent",
            borderColor: "#5579D1",
            pointBackgroundColor: "#5579D1",
            pointBorderColor: "#5579D1",
            pointRadius: 6,
            pointHoverRadius: 9,
          },
          {
            label: "Provider paid avg.",
            data: averageMonthlyData,
            backgroundColor: "transparent",
            borderColor: "#5579D1",
            borderDash: [5, 5],
            pointBackgroundColor: "#5579D1",
            pointBorderColor: "#5579D1",
            pointRadius: 5,
            pointHoverRadius: 9,
          },
          {
            label: "Last year paid avg.",
            data: oneYearAgoData,
            backgroundColor: "transparent",
            borderColor: "var(--red)",
            borderDash: [5, 5],
            pointBackgroundColor: "var(--red)",
            pointBorderColor: "var(--red)",
            pointRadius: 5,
            pointHoverRadius: 9,
          },
        ],
      };
    },
    getBillingSummaryData() {
      this.job = null;
      this.billingSummaryLoading = true;
      var params = {
        start_date: this.chartStartDate,
        end_date: this.chartEndDate,
      };
      if (this.provider) {
        params.provider_id = this.provider.provider_id;
      } else if (this.practice) {
        params.practice_id = this.practice.practice_id;
      }
      var vm = this;
      authenticated_request({
        method: "get",
        url: make_url("/reports/providers/billing_summary").toString(),
        params: params,
      })
        .then(function (response) {
          const items = response.data;
          const job = response.data?.payload?.job;
          if (job) 
            vm.job = job;
          else
            vm.setBillingSummaryData(items);
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(function () {
          vm.billingSummaryLoading = false;
        });
    },
    getBillingBarStyle(range) {
      var styleObject = {
        backgroundColor: "#7793DA",
        width: "0px",
      };
      var rangeAmount = 0;
      switch (range) {
        case "balance":
          rangeAmount = this.totalBalance;
          break;
        case "paid":
          rangeAmount = this.totalPaid;
          break;
        case "adjustments":
          rangeAmount = this.totalAdjustments;
          break;
        case "write-offs":
          rangeAmount = this.totalWriteOffs;
          break;
        default:
          break;
      }
      if (rangeAmount != 0) {
        var percent = (
          (Number(rangeAmount) / Number(this.totalBillingValue || 0)) *
          100
        ).toFixed(0);
        styleObject.width = percent + "%";
      }
      return styleObject;
    },
    getBillingBarPercentage(amount) {
      return (
        (Number(amount) / Number(this.totalBillingValue || 0)) * 100 || 0
      ).toFixed(1);
    },
    getOustandingBarStyle(range) {
      var styleObject = {
        width: "0px",
      };
      var rangeAmount = 0;
      switch (range) {
        case "active":
          rangeAmount = this.totalOutstandingActive[0];
          break;
        case "errors":
          rangeAmount = this.totalOutstandingError[0];
          break;
        case "unresolved":
          rangeAmount = this.totalUnresolvedClaims;
          break;
        default:
          break;
      }
      if (rangeAmount != 0) {
        var percent = (rangeAmount / this.totalOutstandingClaims) * 100;
        styleObject.width = percent + "%";
      }
      return styleObject;
    },
  },
  computed: {
    doAllowPracticeTotals() {
      return this.practiceData != null;
    },
    displayPracticeTotals() {
      return this.providerOrPractice == 'practice';
    },
    totalCharged() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.charged;
      } else {
        return this.providerData?.charged;
      }
    },
    totalEncounters() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.encounters;
      } else {
        return this.providerData?.encounters;
      }
    },
    totalBalance() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.balance;
      } else {
        return this.providerData?.balance;
      }
    },
    totalPaid() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.paid;
      } else {
        return this.providerData?.paid;
      }
    },
    totalAdjustments() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.adjustments;
      } else {
        return this.providerData?.adjustments;
      }
    },
    totalWriteOffs() {
      if (this.displayPracticeTotals) {
        return this.practiceData?.writeoffs;
      } else {
        return this.providerData?.writeoffs;
      }
    },
    totalBillingValue() {
      return (
        Number(this.totalBalance || 0) +
        Number(this.totalPaid || 0) +
        Number(this.totalAdjustments || 0) +
        Number(this.totalWriteOffs || 0)
      );
    },
    today() {
      var d = new Date();
      this.zeroHoursAndMinutes(d);
      return d.toISOString();
    },
    firstDayOfQuarter() {
      var d = new Date();
      d.setDate(1);
      d.setMonth(Math.floor(d.getMonth() / 3) * 3);
      this.zeroHoursAndMinutes(d);
      return d.toISOString();
    },
    firstDayOfYear() {
      var d = new Date();
      d.setDate(1);
      d.setMonth(1);
      this.zeroHoursAndMinutes(d);
      return d.toISOString();
    },
    firstDayOfLastMonth() {
      var d = new Date();
      d.setDate(0);
      d.setDate(1);
      this.zeroHoursAndMinutes(d);
      return d.toISOString();
    },
    lastDayOfLastMonth() {
      var d = new Date();
      d.setDate(0);
      this.zeroHoursAndMinutes(d);
      return d.toISOString();
    },
    chartStartDate() {
      if (this.chartDateRange == "quarter") {
        return this.firstDayOfQuarter;
      } else if (this.chartDateRange == "year") {
        return this.firstDayOfYear;
      } else if (this.chartDateRange == "month") {
        return this.firstDayOfLastMonth;
      }
      return null;
    },
    chartEndDate() {
      if (this.chartDateRange == "quarter") {
        return this.today;
      } else if (this.chartDateRange == "year") {
        return this.today;
      } else if (this.chartDateRange == "month") {
        return this.lastDayOfLastMonth;
      }
      return null;
    },
  },
  watch: {
    provider(newValue) {
      console.log("provider changed");
      if (newValue) {
        this.getBillingSummaryData();
      }
    },
    practice(newValue) {
      console.log("practice_changed");
      if (newValue) {
        this.getBillingSummaryData();
      }
    },
    chartDateRange(newValue) {
      console.log("chartDateRange changed");
      if (newValue) {
        this.getBillingSummaryData();
      }
    },
  },
  async mounted() {
    if (this.practice || this.provider) {
      this.getBillingSummaryData();
    }
  },
};
</script>

<style scoped>
.billing-summary-card {
  position:relative;
  width: 100%;
  border-radius: 15px;
}
/* billing summary styles */
.monthly-totals-chart {
  /* DO NOT APPLY flex, required to be position: relative by chartjs */
  position: relative; /* required by chartjs */
  background-color: #f2f6fc;
  border-radius: 15px;
  padding: 16px 16px 24px 16px;
}
.small-dollar-amount {
  color: #010c14;
  font-weight: 500;
  font-size: 16px;
}
.billing-percentage {
  color: #aabce8;
  font-weight: bold;
  font-size: 16px;
}
.encounters-line {
  color: #010c14;
  font-weight: 500;
  font-size: 16px;
}
.practice-total {
  color: #010c14;
  font-weight: 200;
  font-size: 16px;
}
.thin-bar-background {
  background-color: #e0eaf8;
  height: 7px;
  border-radius: 4px;
}
.thin-bar-overlay {
  height: 7px;
  border-radius: 4px;
  margin-top: -7px;
}
.card-header-small {
  color: #010c14;
  font-weight: 500;
  font-size: 14px;
}
.bold-text-head {
  font-size: 28px;
  font-weight: bold;
  color: var(--dark);
  z-index: 1;
}
.no-data-text {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.card-tab-active {
  font-size: 12px;
  font-weight: bold;
  color: var(--dark);
}
.card-tab-inactive {
  font-size: 12px;
  color: #9ca3ad;
}
</style>
