<template>
<b-container>
    <b-row>
      <b-col>
        <div class="d-flex pl-4 transition" v-if="hasPermission('see_other_provider_metrics')">
            <b-form-group
            label="View Provider Dashboard as provider:">
                <multiselect
                    openDirection="bottom"
                    placeholder="Search by provider name" 
                    :custom-label="providerLabel"
                    v-model="multiselectSelectedProvider"
                    track-by="provider_id"
                    :options="selectableProviders"
                    :loading="providersLoading"
                    :internal-search="false"
                    :show-labels="false"
                    @search-change="debounceProviders"
                    :showNoOptions="true"
                    :allow-empty="true">
                    <template slot="noResult">
                        No providers matched your search
                    </template>
                    <template slot="noOptions">
                        Start typing a provider name...
                    </template>
                </multiselect>
            </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="pl-3">
      <!-- Widgets -->
      <b-col class="transition" :cols="scheduleOpen ? 8: 11">
        <!-- Greeting card -->
        <provider-greeting-widget :provider="selectedProvider" :practice="user_practice"/>
        <!-- Billing summary card for e.g. a practice provider -->
        <div class="avo-header-text-med mb-3">
            Billing summary
        </div>
        <billing-summary-widget :provider="selectedProvider" :practice="user_practice"/>
        <b-row>
          <b-col>
            <average-time-widget :provider="selectedProvider" :practice="user_practice"/>
          </b-col>
          <b-col>
            <div class="active-ar-card shadow mb-3">
              <average-ar-widget :provider="selectedProvider"/>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" class="mb-5">
          <top-procedures-widget :provider="selectedProvider"/>
          </b-col>
          <!-- <b-col>
          <top-procedures-widget showPracticeData :provider="selectedProvider"/>
          </b-col> -->
          <b-col lg="6" class="mb-5">
          <top-diagnoses-widget :provider="selectedProvider"/>
          </b-col>
          <!-- <b-col>
          <top-diagnoses-widget showPracticeData :provider="selectedProvider"/>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-5">
            <top-office-encounters-widget :provider="selectedProvider"/>
          </b-col>
        </b-row>
        <b-row>
          <!-- Active AR by age -->
          <b-col lg="6" class="mb-5">
            <div class="active-ar-chart-card shadow">
              <div class="d-flex justify-content-between align-items-center mb-4">
                <div class="card-header-small">Active A/R by age</div>
                <div class="d-flex justify-content-end align-items-center">
                  <div role="button" :class="[ARByAgeFilterSelection == 'All' ? 'card-tab-active' : 'card-tab-inactive', 'mr-2']" @click="ARByAgeFilterSelection = 'All'">All</div>
                  <div role="button" :class="[ARByAgeFilterSelection == 'Insurance' ? 'card-tab-active' : 'card-tab-inactive', 'mr-2']" @click="ARByAgeFilterSelection = 'Insurance'">Insurance</div>
                  <div role="button" :class="[ARByAgeFilterSelection == 'Patient' ? 'card-tab-active' : 'card-tab-inactive']" @click="ARByAgeFilterSelection = 'Patient'">Patient</div>
                </div>
              </div>
                  <div v-if="ARByAgeLoading" class="spinner-container">
                      <b-spinner></b-spinner>
                  </div>
                  <active-ar-bar-chart :chartData="activeARBarChartData" :styles="{height:'100%', position:'relative'}"></active-ar-bar-chart>
              </div>
          </b-col>
        </b-row>
        <div class="avo-header-text-med mb-3">
            Claims Summary
        </div>
        <b-row>
        <!-- Rejections/denials -->
        <b-col cols="6">
        <div class="rejected-claims-card mb-5">
          <b-overlay variant="transparent" :show="rejectionDenialsLoading">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="d-flex align-items-center">
                <div role="button" :class="[rejectionDenialSelection == 'Rejections' ? 'card-tab-active' : 'card-tab-inactive', 'mr-2']" @click="rejectionDenialSelection = 'Rejections'">Rejections</div>
                <div role="button" :class="[rejectionDenialSelection == 'Denials' ? 'card-tab-active' : 'card-tab-inactive']" @click="rejectionDenialSelection = 'Denials'">Denials</div>
              </div>
            </div>
            <div class="d-flex align-items-center mb-3">
              <img class="rejected-claim-img" :src="require('@/assets/rejected-claim-icon.svg')"/>
              <div v-if="rejectionDenialSelection == 'Rejections' && rejectionDenialsData" class="d-flex flex-column">
                <div style="font-size:12px;font-weight:500;color:#43464A;margin-top:4px;">Rejected claims</div>
                <div class="stat-text-med">{{Number(rejectionDenialsData.rejection_count || 0).toLocaleString()}}</div>
              </div>
              <div v-else-if="rejectionDenialSelection == 'Denials' && rejectionDenialsData" class="d-flex flex-column">
                <div style="font-size:12px;font-weight:500;color:#43464A;margin-top:4px;">Denied claims</div>
                <div class="stat-text-med">{{Number(rejectionDenialsData.denial_count || 0).toLocaleString()}}</div>
              </div>
            </div>
            <div class="top-reasons-box">
              <div v-if="rejectionDenialSelection == 'Rejections' && rejectionDenialsData">
              <div class="mb-2" style="font-weight:500">Top rejection reasons</div>
                <div v-if="rejectionDenialsData.rejected_claims_by_type.length > 0">
                  <b-row v-for="(reason, index) in rejectionDenialsData.rejected_claims_by_type" :key="index">
                    <b-col cols="6">
                      {{reason.clearinghouse_error_type}}
                    </b-col>
                    <b-col cols="3">
                      {{reason.claim_count}}
                    </b-col>
                    <b-col cols="3" style="font-weight:500">
                      {{getRejectionTypePercentage(reason.claim_count)}}%
                    </b-col>
                  </b-row>
                </div>
                <div v-else class="no-data-text my-3">
                  No rejected claims
                </div>
              </div>
              <div v-else-if="rejectionDenialSelection == 'Denials' && rejectionDenialsData">
                <div class="mb-2" style="font-weight:500">Top denial reasons</div>
                <div v-if="rejectionDenialsData.denied_claims_by_type.length > 0">
                  <b-row v-for="(reason, index) in rejectionDenialsData.denied_claims_by_type" :key="index">
                    <b-col cols="6">
                      {{reason.denial_type}}
                    </b-col>
                    <b-col cols="3">
                      {{reason.claim_count}}
                    </b-col>
                    <b-col cols="3" style="font-weight:500">
                      {{getDenialTypePercentage(reason.claim_count)}}%
                    </b-col>
                  </b-row>
                </div>
                <div v-else class="no-data-text my-3">
                  No denied claims
                </div>
              </div>
            </div>
          </b-overlay>
        </div>   
        </b-col>
        </b-row>
      </b-col>
      <!-- Schedule -->
      <b-col class="pr-0 transition" :cols="scheduleOpen ? 4 : 1">
        <div class="right-column p-4">
        <div class="expand-nav-div">
          <b-iconstack
                @click="scheduleOpen = !scheduleOpen"
                class="cursor-pointer mb-3">
            <b-icon icon="circle-fill" variant="primary" scale="2"></b-icon>
            <b-icon v-if="!scheduleOpen" variant="light" icon="chevron-left" />
            <b-icon v-else variant="light" icon="chevron-right" />
          </b-iconstack>
        </div>
          <div class="avo-header-text-med mb-3 transition" :class="scheduleOpen ? '': 'rotate'">
            Schedule
          </div>
          <div>
            <provider-schedule v-show="scheduleOpen" :provider="selectedProvider"/>
          </div>
        </div>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import { make_url, authenticated_request, 
         formatDate, formatDecimal, formatTime, debounce, 
         permissionsMixin 
       } from '../../common.js'
import Multiselect from 'vue-multiselect'
import ActiveArBarChart from '../charts/portalCharts/ActiveARBarChart.vue'
import ProviderGreetingWidget from './reportWidgets/ProviderGreetingWidget.vue'
import AverageArWidget from './reportWidgets/AverageArWidget.vue'
import BillingSummaryWidget from './reportWidgets/BillingSummaryWidget.vue'
import AverageTimeWidget from './reportWidgets/AverageTimeWidget.vue'
import TopProceduresWidget from './reportWidgets/TopProceduresWidget.vue'
import TopOfficeEncountersWidget from './reportWidgets/TopOfficeEncountersWidget.vue'
import TopDiagnosesWidget from './reportWidgets/TopDiagnosesWidget.vue'
import ProviderSchedule from './ProviderSchedule.vue'

export default {
  name: 'ProviderDash',
  components: {
    Multiselect,
    ActiveArBarChart, 
    ProviderGreetingWidget,
    AverageArWidget,
    BillingSummaryWidget,
    AverageTimeWidget,
    TopProceduresWidget,
    TopOfficeEncountersWidget,
    TopDiagnosesWidget,
    ProviderSchedule,
  },
  mixins: [permissionsMixin,],
  data(){
      return {
        scheduleOpen: true,

        // Active A/R by Age stuff 
        ARByAgeFilterSelection: "All",
        ARByAgeAllData: [],
        ARByAgeInsuranceData: [],
        ARByAgePatientData: [],
        ARByAgeLoading: false,
        activeARBarChartData: {
            labels: ["0-30", "31-60", "61-90", "91-120", "121-150", "151+"],
            datasets: [
                {
                    backgroundColor: '#A8C3F3',
                    data: this.ARByAgeChosenData
                }
            ]
        },

        // Rejections / Denials card
        rejectionDenialSelection: "Rejections",
        rejectionDenialsLoading: false,
        rejectionDenialsData: null,

        // for superadmin provider/practice multiselects
        providersLoading: false,
        selectableProviders: [],
        multiselectSelectedProvider: null,
        providerSearchText: "",
      }
  },
  methods: {
    getClaimsARByAge(){
      var vm = this;
      vm.ARByAgeLoading = true;

      var params = {}
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      }
      authenticated_request({
          method: "get",
          url: make_url("/reports/claims/ar_by_age").toString(),
          params: params
      }).then(function(response){
          const items = response.data;
          vm.ARByAgeAllData.push(items.total_ar_0_30_days);
          vm.ARByAgeAllData.push(items.total_ar_31_60_days);
          vm.ARByAgeAllData.push(items.total_ar_61_90_days);
          vm.ARByAgeAllData.push(items.total_ar_91_120_days);
          vm.ARByAgeAllData.push(items.total_ar_121_150_days);
          vm.ARByAgeAllData.push(items.total_ar_151_days);
          vm.activeARBarChartData.datasets[0].data = vm.ARByAgeAllData;

          vm.ARByAgeInsuranceData.push(items.payer_ar_0_30_days);
          vm.ARByAgeInsuranceData.push(items.payer_ar_31_60_days);
          vm.ARByAgeInsuranceData.push(items.payer_ar_61_90_days);
          vm.ARByAgeInsuranceData.push(items.payer_ar_91_120_days);
          vm.ARByAgeInsuranceData.push(items.payer_ar_121_150_days);
          vm.ARByAgeInsuranceData.push(items.payer_ar_151_days);

          vm.ARByAgePatientData.push(items.patient_ar_0_30_days);
          vm.ARByAgePatientData.push(items.patient_ar_31_60_days);
          vm.ARByAgePatientData.push(items.patient_ar_61_90_days);
          vm.ARByAgePatientData.push(items.patient_ar_91_120_days);
          vm.ARByAgePatientData.push(items.patient_ar_121_150_days);
          vm.ARByAgePatientData.push(items.patient_ar_151_days);
      }).catch(function(error){
          console.log(error.response)
      }).finally(()=>{
          vm.ARByAgeLoading = false;
      });
    },
    getOutstandingClaimsBreakdown(){
      var vm = this;
      vm.outstandingClaimsDataLoading = true;

      var params = {}
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      }
      authenticated_request({
          method: "get",
          url: make_url("/reports/claims/active_claims_breakdown").toString(),
          params: params
      }).then(function(response){
          vm.outstandingClaimsData = response.data;
          // vm.closedClaimsData = response.data;
      }).catch(function(error){
          console.log(error.response)
      }).finally(()=>{
          vm.outstandingClaimsDataLoading = false;
      });

    },
    getClaimsClosedStats(){
      var vm = this;
      vm.closedStatsLoading = true;

      var params = {}
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      }
      authenticated_request({
          method: "get",
          url: make_url("/reports/claims/closed").toString(),
          params: params
      }).then(function(response){
          vm.closedClaimsData = response.data;
      }).catch(function(error){
          console.log(error.response)
      }).finally(()=>{
          vm.closedStatsLoading = false;
      });
    },
    getClaimsRejectionDenials(){
      var vm = this;
      vm.rejectionDenialsLoading = true;

      var params = {}
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      }
      authenticated_request({
          method: "get",
          url: make_url("/reports/claims/rejections_denials").toString(),
          params: params
      }).then(function(response){
          vm.rejectionDenialsData = response.data
      }).catch(function(error){
          console.log(error.response)
      }).finally(()=>{
          vm.rejectionDenialsLoading = false;
      });
    },
    getRejectionTypePercentage(count){
      return ((count/this.rejectionDenialsData.rejection_count || 0) * 100).toFixed(0)
    },
    getDenialTypePercentage(count){
      return ((count/this.rejectionDenialsData.denial_count || 0) * 100).toFixed(0)
    },
    percentStateActive(stateCount){
      return Number((stateCount/this.totalOutstandingActive[0] || 0) * 100).toFixed(1);
    },
    percentStateError(stateCount){
      return Number((stateCount/this.totalOutstandingError[0] || 0) * 100).toFixed(1);
    },

    providerSearchChange: function(searchQuery) {
        this.providersLoading = true;
        this.providerSearchText = searchQuery
        if (searchQuery.length > 0 ) {
            this.providerAutocomplete();
        } else {
            this.providersLoading = false;
        }
    },
    providerAutocomplete() {
        var vm = this;
        const url = make_url("/reports/providers/autocomplete");
        authenticated_request({
            method: "get",
            url: url,
            params: {
                search: this.providerSearchText,
                only_practice_providers: true,
                limit: 10
            }
        }).then(function(response){
            vm.providersLoading = false;

            const providers = response.data.providers;
            vm.selectableProviders = providers || [];
            return providers || [];
        }).catch(function(errors){
            vm.showError(errors);
        });
    },
    providerLabel(provider){
        return provider.full_name_with_suffix
    },
    formatDate,
    formatTime,
    formatDecimal,
  },
  computed: {
    user_worker_id: function() {
      return localStorage.getItem("worker_id");
    },
    selectedProvider: function() {
      if (this.multiselectSelectedProvider) {
        return this.multiselectSelectedProvider;
      } else if (this.user_provider) {
        return this.multiselectSelectedProvider ? this.multiselectSelectedProvider: this.user_provider;
      }
      return null
    },
    selected_provider_id: function() {
      if (this.selectedProvider) {
        return this.selectedProvider.provider_id;
      }  
      return null
    },
    user_provider: function() {
      return JSON.parse(localStorage.getItem("provider")) || null;
    },
    user_practice: function() {
      return JSON.parse(localStorage.getItem("practice")) || null;
    },
    user_practice_id: function() {
      return localStorage.getItem("practice_id");
    },
    totalOutstandingClaims(){
      return this.totalOutstandingActive[0] + this.totalOutstandingError[0]
    },
    totalOutstandingClaimsValue(){
      return Number(this.totalOutstandingActive[1] || 0) + Number(this.totalOutstandingError[1] || 0)
    },
    totalOutstandingActive(){
      if(this.outstandingClaimsData){
        var totalValue = 0;
        var totalCount = 0;
        this.outstandingClaimsData.active_claims.forEach(function(item){
          totalValue += Number(item.charged || 0)
          totalCount += Number(item.count || 0)
        })
        return [totalCount, totalValue]
      } else {
        return [0,0]
      }
    },
    totalOutstandingError(){
      if(this.outstandingClaimsData){
        var totalValue = 0;
        var totalCount = 0;
        this.outstandingClaimsData.error_claims.forEach(function(item){
          totalValue += Number(item.charged || 0)
          totalCount += Number(item.count || 0)
        })
        return [totalCount, totalValue]
      } else {
        return [0,0]
      }
    },
    percentTotalActive(){
      return Number((this.totalOutstandingActive[0]/this.totalOutstandingClaims || 0) * 100).toFixed(1);
    },
    percentTotalError(){
      return Number((this.totalOutstandingError[0]/this.totalOutstandingClaims || 0) * 100).toFixed(1);
    },
    
    avgClaimsApprovedFirstPass(){
      if(this.closedClaimsData){
        var percentage = (((this.closedClaimsData.first_pass_closed_claim_count || 0)/(this.closedClaimsData.closed_claim_count || 0) || 0)*100).toFixed(0) + '%';
        return percentage;
      }
      return 'N/A'
    },
  },
  watch: {
    ARByAgeFilterSelection(newValue){
      if(newValue == "All"){
        this.activeARBarChartData.datasets[0].data = this.ARByAgeAllData;
      } else if(newValue == "Insurance"){
        this.activeARBarChartData.datasets[0].data = this.ARByAgeInsuranceData;
      } else if(newValue == "Patient"){
        this.activeARBarChartData.datasets[0].data = this.ARByAgePatientData;
      }
    },
    selectedProvider(newValue){
      if (newValue) {
        this.$store.commit("providerPortal/setSelectedProviderId", newValue.provider_id);
      }
      this.getClaimsARByAge();
      this.getClaimsRejectionDenials();
      this.getClaimsClosedStats();
      this.getOutstandingClaimsBreakdown();
    }
  },
  async mounted() {
    if(!this.hasPermission('see_other_provider_metrics')){
      this.getClaimsARByAge();
      this.getClaimsRejectionDenials();
      this.getClaimsClosedStats();
      this.getOutstandingClaimsBreakdown();
    }
    
    if(this.user_provider && this.hasPermission('see_other_provider_metrics')){
      this.selectedProvider = this.user_provider
    }
  },
  beforeMount(){
    this.$store.commit("providerPortal/setActiveNavTab", 'dashboard');
  },
  created() {
    this.debounceProviders = debounce(this.providerSearchChange, 300)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width : 800px) {
   .container { max-width: 100%; } 
}
::v-deep .multiselect__spinner {
    background: #DDE4F5 !important;
}

.chart-container {
  display: flex; 
  justify-content: center;
  align-items: center;
  height: 100px;
  /* width: 90px; */
  padding: 16px 16px 24px 0;
}
.spinner-container {
    position: absolute;
    top:50%;
    left:45%
}
.card-header-small {
  color: #010C14;
  font-weight: 500;
  font-size: 14px;
}
.no-data-text {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.card-tab-active {
  font-size: 12px;
  font-weight: bold;
  color: var(--dark);
}
.card-tab-inactive {
  font-size: 12px;
  color: #9ca3ad;
}

/* side bar related styles */
.quick-action-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  width: 113px;
  height: 113px;
  border-radius: 15px;
  color: white;
  font-size: 12px;
  box-shadow: 1px 1px 10px #16171814;
}
.add-rectangle {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px 0px 5px 0px;
  background-color: white;
  height: 25px;
  width: 25px;
}
.plus-icon {
    padding:4px 0 0 6px;
}
.new-preauth-icon {
  background: transparent linear-gradient(136deg, var(--blue) 0%, #3B5492 100%) 0% 0% no-repeat padding-box;
}
.new-claim-icon {
  background: transparent linear-gradient(141deg, #F37C73 0%, var(--red) 100%) 0% 0% no-repeat padding-box;
}
.new-patient-icon {
  background: transparent linear-gradient(144deg, #8BBEFF 0%, #3D93FF 100%) 0% 0% no-repeat padding-box;
}

/* active ar stuff */
.active-ar-card {
  padding: 24px;
  background-color: white;
  border-radius: 15px;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  min-height: 100%;
  width: 100%;
  border-radius: 15px 0px 0px 0px;
  box-shadow: -3px 1px 10px #16171814;
}

.expand-nav-div {
  position: relative;
  right:30px;
}
.rotate {
  transform: rotate(90deg);
}
.right-column-closed {
  width:90px;
  height:100%;
}
.transition {
  transition: ease 0.33s;
}

.active-ar-chart-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 24px;
  min-height: 300px;
  width: 100%;
  height: 100%;
}

/* oustanding claims styles */
.outstanding-claims-card {
  min-height:291px;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent linear-gradient(153deg, #33487D 0%, #223053 100%) 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 10px #16171814;
  border-radius: 15px;
  padding: 24px;
}
.outstanding-claims-icon {
  min-width:16px;
  min-height:16px;
  max-width:16px;
  max-height:16px;
  border-radius:8px;
  border:2px solid #88A1DE;margin:5px 8px 0 0;
}
.oustanding-claims-bar {
  display:flex;
  align-items: center;
}
.active-claims-bar {
  height:30px;
  background: transparent linear-gradient(90deg, #88A1DE 0%, var(--blue) 100%) 0% 0% no-repeat padding-box;
  border-radius: 50px 0px 0px 50px;
  border-right: 1px solid white;
}
.error-claims-bar {
  height:30px;
  background: transparent linear-gradient(270deg, #F37C73 0%, var(--red) 100%) 0% 0% no-repeat padding-box;
  /* border-right: 1px solid white; */
  border-radius: 0px 50px 50px 0px;
  border-left: 0.5px solid white;
}
.unresolved-claims-bar {
  height:30px;
  background: transparent linear-gradient(270deg, #FEC377 0%, #FE9817 100%) 0% 0% no-repeat padding-box;
  border-radius: 0px 50px 50px 0px;
  border-left: 0.5px solid white;
}

/* active/error/unresolved claim breakdown cards */
.claim-breakdown-card {
  /* min-height:150px; */
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #16171814;
}
.breakdown-card-head {
  position: relative;
  display:flex;
  align-items:center;
  justify-content: space-between;
  padding: 16px;
  padding-left:120px;
  height:92px;
  background-color: white;
  box-shadow: 1px 1px 10px #16171814;
  border-radius: 15px;
}
.breakdown-img {
  position:absolute;
  left:0px;
  top: 0px;
}
.breakdown-collapse-body {
  font-size: 14px;
  color: var(--dark);
  padding: 24px 16px;
}
.breakdown-collapse-body .row {
  padding: 8px 0;
  border-bottom: 0.5px solid #C9D2DF;
}
.toggle-collapse-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue);
  font-size: 14px;
  padding: 8px 0;
}

/* total claims submitted */
.total-claims-submitted-card {
  background-color: white;
  border-radius: 15px;
  padding: 24px;
}
.total-paid-box,
.total-denied-box {
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:12px;
  min-height: 100px;
  border-radius: 10px;
  width: 100%;
  background-color: #ECF2FA
}
.total-paid-subcard,
.total-denied-subcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left:16px;
  min-height: 80px;
  min-width:160px;
  border-radius: 10px;
  background-color: white;
}
.first-pass-box {
  position: relative;
  width:100%;
  margin-right:-15px;
  margin-bottom: -15px;
}
.first-pass-img {
  width:108%;
  height:auto;
}
.first-pass-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  width:100%;
  height:75%;
  padding: 0 10%;
}
/* rejected/denied claims card */
.rejected-claims-card{
  min-height:150px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 1px 1px 10px #16171814;
  padding: 24px;
}
.top-reasons-box {
  background-color:#F2F6FC;
  border-radius:15px;
  height:100%;
  display:flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--dark);
  padding:24px;
}
.top-reasons-box .row {
  padding: 8px 0;
  border-bottom:0.5px solid #C9D2DF;
  padding-left:9px;
  padding-right:9px;
  margin-left:-24px;
  margin-right: -24px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>