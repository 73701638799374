<template>
  <div class="widget-container">
    <job-overlay :job="job" @finished="onFinished">
      <b-row align-h="around" class="m-0">
        <!-- Average time for claim creation -->
        <b-col
          v-for="data in timeCardData"
          :key="data.label"
          :class="`avg-pay-time-card ${data.gradient}`"
        >
          <div class="mb-3 widget-heading-text">
            {{ data.label }}
          </div>
          <b-overlay variant="transparent" :show="loading">
            <div class="d-flex align-items-center">
              <div class="widget-days-text" style="font-size: 32px">
                {{ formatDecimal(data.value, "N/A") }}
              </div>
              <div class="ml-2 mt-1 widget-days-text">days</div>
            </div>
          </b-overlay>
          <div class="clock-img">
            <img :src="require(`@/assets/${data.clock}`)" />
          </div>
        </b-col>
      </b-row>
    </job-overlay>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatDecimalShort as formatDecimal,
} from "../../../common";
import JobOverlay from "../../JobOverlay";

export default {
  name: "AverageTimeWidget",
  props: {
    provider: {
      type: Object,
      required: false,
    },
    practice: {
      type: Object,
      required: false,
    },
  },
  components: {
    JobOverlay,
  },
  data() {
    return {
      avgClaimCreationTime: null,
      avgSubmissionTime: null,
      avgPaymentTime: null,

      loading: false,

      job: null,
    };
  },
  methods: {
    formatDecimal,
    onFinished() {
      this.getTimeAverages();
    },
    setTimeAverages(result) {
      this.avgClaimCreationTime = result.average_claim_creation_time || null;
      this.avgSubmissionTime = result.average_claim_submission_time || null;
      this.avgPaymentTime = result.average_claim_payment_time || null;
    },
    getTimeAverages() {
      var vm = this;

      var params = {};
      if (this.selected_provider_id) {
        params.provider_ids = this.selected_provider_id;
      } else if (this.user_practice_id) {
        params.practice_ids = this.user_practice_id;
      } else {
        return;
      }

      vm.loading = true;
      authenticated_request({
        method: "get",
        url: make_url("/reports/claims/average_age").toString(),
        params: params,
      })
        .then(function (response) {
          vm.setTimeAverages(response.data);
          vm.job = response.data?.payload?.job || null;
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(() => {
          vm.loading = false;
        });
    },
  },
  computed: {
    timeCardData() {
      return [
        {
          label: "Average time to claim creation",
          value: this.avgClaimCreationTime,
          gradient: "blue-gradient",
          clock: "clock-blue.svg",
        },
        {
          label: "Average time to claim submission",
          value: this.avgSubmissionTime,
          gradient: "orange-gradient",
          clock: "clock-orange.svg",
        },
        {
          label: "Average time to first payment",
          value: this.avgPaymentTime,
          gradient: "green-gradient",
          clock: "clock-green.svg",
        },
      ];
    },
    selected_provider_id() {
      if (this.provider) {
        return this.provider.provider_id;
      }
      return null;
    },
    user_practice_id() {
      if (this.practice) {
        return this.practice.practice_id;
      }
      return null;
    },
  },
  watch: {
    provider: function (newVal, oldVal) {
      if (newVal) {
        this.getTimeAverages();
      }
    },
    practice: function (newVal, oldVal) {
      if (newVal) {
        this.getTimeAverages();
      }
    },
  },
  async mounted() {
    this.getTimeAverages();
  },
};
</script>


<style scoped>
/* avg payment time card */
.widget-container {
  position: relative;
}
.avg-pay-time-card {
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 300px;
  min-height: 170px;
  max-height: 400px;
  padding: 24px 32px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 15px;
}
.blue-gradient {
  background: transparent
    linear-gradient(137deg, var(--blue) 0%, var(--light-blue) 100%) 0% 0%
    no-repeat padding-box;
}
.orange-gradient {
  background: transparent
    linear-gradient(137deg, var(--orange) 0%, var(--dark-orange) 100%) 0% 0%
    no-repeat padding-box;
}
.red-gradient {
  background: transparent
    linear-gradient(137deg, var(--red) 0%, var(--dark-red) 100%) 0% 0% 
    no-repeat padding-box;
}
.green-gradient {
  background: transparent
    linear-gradient(137deg, var(--green) 0%, var(--dark-green) 100%) 0% 0%
    no-repeat padding-box;
}
.widget-heading-text {
  font-size: 14px;
  font-weight: 600;
  z-index: 1;
}
.widget-days-text {
  font-size: 16px;
  font-weight: bold;
  z-index: 1;
}
.clock-img {
  position: absolute;
  bottom: -15px;
  right: -15px;
  z-index: 0;
}
</style>