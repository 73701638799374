<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="card-header-small mb-2">Active Accounts Receivable</div>
    <div class="d-flex justify-content-around align-items-center">
      <b-overlay variant="transparent" :show="totalARLoading">
        <div class="d-flex flex-column total-active-box">
          <div class="data-label">Total active</div>
          <div class="stat-text">{{ totalActiveARCount || 0 }}</div>
          <div class="value-data">
                Value: {{ formatDollars(totalActiveARValue) }}
          </div>
        </div>
      </b-overlay>
      <b-overlay variant="transparent" :show="totalARLoading">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column justify-content-center">
            <div class="data-label">Insurance A/R</div>
            <div class="stat-text">{{ insuranceARCount || 0 }}</div>
            <div class="value-data">
                Value: {{ formatDollars(insuranceARValue) }}
            </div>
          </div>
          <div class="chart-container" style="width: 90px">
            <progress-circle-chart
              :percent="percentInsAR"
              :styles="{ height: '50px', position: 'relative' }"
            ></progress-circle-chart>
          </div>
        </div>
      </b-overlay>
      <b-overlay variant="transparent" :show="totalARLoading">
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column justify-content-center">
            <div class="data-label">Patient A/R</div>
            <div class="stat-text">{{ patientARCount || 0 }}</div>
            <div class="value-data">
                Value: {{ formatDollars(patientARValue) }}
            </div>
          </div>
          <div class="chart-container" style="width: 90px">
            <progress-circle-chart
              :percent="percentPatientAR"
              :styles="{ height: '50px', position: 'relative' }"
            ></progress-circle-chart>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import ProgressCircleChart from "../../charts/ProgressCircleChart.vue";
import { authenticated_request, make_url, formatDollarsNoCents as formatDollars } from "../../../common.js";

export default {
  name: "AverageArWidget",
  components: {
    ProgressCircleChart,
  },
  props: ["provider", "practice"],
  data: function () {
    return {
      totalARLoading: false,

      totalActiveARCount: 0,
      totalActiveARValue: 0,
      insuranceARCount: 0,
      insuranceARValue: 0,
      patientARCount: 0,
      patientARValue: 0,
    };
  },
  methods: {
    formatDollars,
    getClaimsTotalAR: function() {
      var vm = this;
      vm.totalARLoading = true;

      var params = {};
      if (this.provider_id) {
        params.provider_ids = this.provider_id;
      } else if (this.practice_id) {
        params.practice_ids = this.practice_id;
      }
      authenticated_request({
        method: "get",
        url: make_url("/reports/claims/total_ar").toString(),
        params: params,
      })
        .then(function (response) {
          vm.totalActiveARCount = response.data.total_ar_count;
          vm.insuranceARCount = response.data.payer_ar_count;
          vm.patientARCount = response.data.patient_ar_count;

          vm.totalActiveARValue = response.data.total_ar_value;
          vm.insuranceARValue = response.data.payer_ar_value;
          vm.patientARValue = response.data.patient_ar_value;
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          vm.totalARLoading = false;
        });
    },
  },
  computed: {
    provider_id: function () {
      return this.provider?.provider_id;
    },
    practice_id: function () {
      return this.practice?.practice_id;
    },
    percentInsAR: function () {
        return (this.insuranceARCount / this.totalActiveARCount || 0) * 100;
    },
    percentPatientAR: function () {
        return (this.patientARCount / this.totalActiveARCount || 0) * 100;
    },
  },
  watch: {
    provider: function () {
      this.getClaimsTotalAR();
    },
    practice: function () {
      this.getClaimsTotalAR();
    },
  },
};
</script>

<style scoped>
.chart-container {
  display: flex; 
  justify-content: center;
  align-items: center;
  height: 100px;
  /* width: 90px; */
  padding: 16px 16px 24px 0;
}
.total-active-box {
  padding: 18px;
  background-color:#F2F6FC;
  border-radius:15px;
}
.data-label {
  font-size:12px;
  color:var(--gray-800);
}
.value-data {
  font-size:12px;
  color: var(--blue)
}
</style>