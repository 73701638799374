<template>
    <Bar
        :chart-data="chartData"
        :chart-options="options"
        :styles="styles"
    />
</template>
<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs/legacy'

export default {
  name: 'ActiveArBarChart',
  props: ['chartData', 'styles'],
  components: { Bar },
  data() {
      return {
        options: {
            borderRadius: 8,
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                       label: function(tooltipItem) {
                              return tooltipItem.yLabel;
                       }
                    }
                },
            },
            barThickness: 30,
            scales: {
                x: {
                    grid: {
                        display:false
                    },
                    title: {
                        display: true,
                        text: 'Days'
                    },
                },
                y: {
                    grid: {
                        display:false
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 6,

                        // simple currency conversion
                        callback: function(value) {
                            if(value < 0){
                                value = Math.abs(value)
                                if (value < 1e3) return "$-" + value;
                                if (value >= 1e3 && value < 1e6) return "$-" + (value / 1e3).toFixed(1) + "K";
                                if (value >= 1e6 && value < 1e9) return "$-" + (value / 1e6).toFixed(1) + "M";
                                if (value >= 1e9 && value < 1e12) return "$-" + (value / 1e9).toFixed(1) + "B";
                                if (value >= 1e12) return "$-" + (value / 1e12).toFixed(1) + "T";
                            }
                            if (value < 1e3) return "$" + value;
                            if (value >= 1e3 && value < 1e6) return "$" + (value / 1e3).toFixed(1) + "K";
                            if (value >= 1e6 && value < 1e9) return "$" + (value / 1e6).toFixed(1) + "M";
                            if (value >= 1e9 && value < 1e12) return "$" + (value / 1e9).toFixed(1) + "B";
                            if (value >= 1e12) return "$" + (value / 1e12).toFixed(1) + "T";
                        }
                    }  
                }
            },
        }
      }
  },
}
</script>