<template>
  <div class="top-procedures-card shadow">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="card-header-small">{{ headerText }}</div>
    </div>
    <donut-with-bar
      :chart-data="topProceduresChartData"
      :data-loading="topProceduresLoading"
    />
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatNumber,
  formatDollarsNoCents as formatDollars,
} from "../../../common.js";
import DonutWithBar from "../../charts/DonutWithBar.vue";

export default {
  name: "TopOfficeEncountersWidget",
  components: {
    DonutWithBar,
  },
  props: {
    provider: {
      type: Object,
      required: false,
      default: null,
    },
    practice: {
      type: Object,
      required: false,
      default: null,
    },
    showPracticeData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      topProceduresChartData: null,
      topProceduresLoading: false,
    };
  },
  computed: {
    provider_id: function () {
      return this.provider?.provider_id;
    },
    practice_id: function () {
      return this.practice?.practice_id;
    },
    request_params: function () {
      if (!this.provider_id && !this.practice_id) {
        return null;
      }
      var params = { limit: 10 };
      if (this.provider_id) {
        params.provider_id = this.provider_id;
      } else if (this.practice_id) {
        params.practice_id = this.practice_id;
      }
      return params;
    },
    headerText: function () {
      if (this.showPracticeData) {
        return "Practice top office encounters";
      }
      return "Your top office encounters";
    },
  },
  methods: {
    formatDollars,
    formatNumber,
    getTopProcedures() {
      const vm = this;
      vm.topProceduresLoading = true;

      if (!vm.request_params) {
        return;
      }

      const promise = authenticated_request({
        method: "get",
        url: make_url("/reports/claims/office_encounters").toString(),
        params: vm.request_params,
      })
        .then(function (response) {
          if (response) {
            const items = response.data;
            
            const pallete = [
              "#7A9CE5",
              "#41B0A5",
              "#D191DF",
              "#EEC05A",
              "#FEA487",
              "#4C3B4D",
              "#A53860",
              "#B0DB43",
            ];
            
            const backgroundColorsGroup = items.group_labels.map(
              (label, index) => pallete[index % pallete.length]
            );
            const mapGroupLabelToColor = function(label) {
              return backgroundColorsGroup[items.group_labels.indexOf(label)];
            }
            const backgroundColorsIndividual = items.individual_groups.map(
              (group, index) => mapGroupLabelToColor(group) 
            );
            
            const groupIndices = items.individual_groups.map(
              group => items.group_labels.indexOf(group)
            );

            vm.topProceduresChartData = {
              hoverBorderWidth: 10,
              datasets: [
                {
                  label: items.group_labels,
                  backgroundColor: backgroundColorsGroup,
                  data: items.group_counts,
                },
                {
                  label: items.individual_labels,
                  backgroundColor: backgroundColorsIndividual,
                  data: items.individual_counts,
                  groups: groupIndices,
                },
              ],
            };
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          vm.topProceduresLoading = false;
        });
      return promise;
    },
  },
  watch: {
    provider: function () {
      this.getTopProcedures();
    },
    practice: function () {
      this.getTopProcedures();
    },
  },
};
</script>

<style scoped>
/* top procedures */
.top-procedures-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 24px;
  min-height: 300px;
  width: 100%;
  height: 100%;
}
.procedure-icon {
  min-width: 26px;
  height: 26px;
  border-radius: 5px;
  background: transparent
    linear-gradient(147deg, #a7bae7 2%, #89a2df, var(--blue) 100%) 0% 0%
    no-repeat padding-box;
}
.card-header-small {
  color: var(--dark);
  font-weight: 500;
  font-size: 14px;
}
.no-data-text {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.card-tab-active {
  font-size: 12px;
  font-weight: bold;
  color: var(--dark);
}
.card-tab-inactive {
  font-size: 12px;
  color: #9ca3ad;
}
.btn-text {
  text-transform: capitalize;
}
</style>