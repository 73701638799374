<template>
    <donut-chart 
        :chartData="chartData" 
        :styles="styles" 
        :loaded="true" 
        :hide-legend="true"
        :hide-tool-tips="true"
    />
</template>

<script>
import DonutChart from './DonutChart.vue';
export default {
    name: 'ProgressCircleChart',
    components: {
        DonutChart
    },
    props: ['percent', 'styles'],
    computed: {
        chartData: function() {
            return {
                labels: ['Percent of Total', 'Remaining'],
                datasets: [{
                    data: [this.percent, 100 - this.percent],
                    backgroundColor: ['#5579d1', '#eaeaea'],
                    borderWidth: 0,
                }],
            };
        },
    }
}
</script>