<template>
  <div class="top-procedures-card shadow">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div class="card-header-small"> {{ headerText }}</div>
      <div class="d-flex justify-content-end align-items-center">
        <div
          role="button"
          v-for="value in ['Claims', 'CPT Codes']"
          :key="value"
          :class="[
            activeTab == value ? 'card-tab-active' : 'card-tab-inactive',
            'mr-2',
          ]"
          @click="activeTab = value"
        >
          {{ value }}
        </div>
      </div>
    </div>
    <b-overlay :show="topProceduresLoading" variant="white">
      <b-row
        v-for="(item, index) in topProcedureSelection"
        :key="index"
        align-v="center"
        class="mb-3"
      >
        <b-col v-if="item.code" cols="8" class="pr-0">
          <div class="d-flex align-items-center">
            <div class="procedure-icon mr-3"></div>
            <div class="d-flex flex-column justify-content-center">
              <div class="d-flex align-items-center">
                <div class="card-header-small mr-3 code-text">{{ item.code }}</div>
                <div class="avo-text-light-small">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col v-if="item.code" cols="4" class="text-right">
          <div class="avo-header-text-med">
            {{ formatter(item.count) }}
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  make_url,
  authenticated_request,
  formatNumber,
} from "../../../common.js";

export default {
  name: "TopDiagnosesWidget",
  props: {
    provider: {
      type: Object,
      required: false,
      default: null,
    },
    practice: {
      type: Object,
      required: false,
      default: null,
    },
    showPracticeData: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "Claims",

      topProceduresByEncounter: null,
      topProceduresByPointer: null,

      topProceduresLoading: false,
    };
  },
  computed: {
    provider_id: function () {
      return this.provider?.provider_id;
    },
    practice_id: function () {
      return this.practice?.practice_id;
    },
    request_params: function () {
      if (!this.provider_id && !this.practice_id) {
        return null;
      }
      var params = { limit: 10 };
      if (this.provider_id) {
        params.provider_id = this.provider_id;
      } else if (this.practice_id) {
        params.practice_id = this.practice_id;
      }
      return params;
    },
    headerText: function () {
      if (this.showPracticeData) {
        return "Practice top ICD codes";
      } 
      return "Your top ICD codes";
    },
    formatter: function () {
        return formatNumber;
    },
    topProcedureSelection: function () {
      const tabMap = {
        'Claims': this.topProceduresByEncounter,
        'CPT Codes': this.topProceduresByPointer,
      };
      if (tabMap[this.activeTab]) {
        return tabMap[this.activeTab];
      } else {
        return this.topProceduresByEncounter;
      }
    },
  },
  methods: {
    formatNumber,
    getTopProcedures() {
      const vm = this;
      vm.topProceduresLoading = true;

      if (!vm.request_params) {
        return;
      }

      const promise = authenticated_request({
        method: "get",
        url: make_url("/reports/claims/diagnoses").toString(),
        params: vm.request_params,
      })
        .then(function (response) {
          if (response) {
            const items = response.data;
            vm.topProceduresByEncounter = items.diagnoses_by_count;
            vm.topProceduresByPointer = items.diagnoses_by_pointer_count;
          }
        })
        .catch(function (error) {
          console.log(error.response);
        })
        .finally(() => {
          vm.topProceduresLoading = false;
        });
      return promise;
    },
  },
  watch: {
    provider: function () {
      this.getTopProcedures();
    },
    practice: function () {
      this.getTopProcedures();
    },
  },
};
</script>

<style scoped>
/* top procedures */
.top-procedures-card {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: 24px;
  min-height: 300px;
  width: 100%;
  height: 100%;
}
.procedure-icon {
  min-width: 26px;
  height: 26px;
  border-radius: 5px;
  background: transparent
    linear-gradient(147deg, #a7bae7 2%, #89a2df, var(--blue) 100%) 0% 0%
    no-repeat padding-box;
}
.card-header-small {
  color: var(--dark);
  font-weight: 500;
  font-size: 14px;
}
.no-data-text {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}
.card-tab-active {
  font-size: 12px;
  font-weight: bold;
  color: var(--dark);
}
.card-tab-inactive {
  font-size: 12px;
  color: #9ca3ad;
}
.code-text {
  width: 42px;
}
</style>