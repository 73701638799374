<template>
    <BaseLineChart
        :chart-data="chartData"
        :chart-options="options"
        :styles="styles"
    />
</template>

<script>
import 'chart.js/auto'
import { Line as BaseLineChart } from 'vue-chartjs/legacy'

export default {
  name: 'MonthlyTotalsLineChart',
  components: { BaseLineChart },
  props: ['chartData', 'styles'],
  data() {
      return {
        options: {
            borderRadius: 10,
            maintainAspectRatio: false,
            responsive: true,
            elements: {
                line: {
                    tension: 0.3,
                },
            },
            plugins: {
                legend: {
                    display: true
                },
            },
            scales: {
                x: {
                    display: true,
                    grid: {
                        display: false
                    },
                },
                y: {
                    grid: {
                        display:false
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 7,
                        
                        // simple currency conversion
                        callback: function(value) {
                            if(value < 0){
                              value = Math.abs(value)
                              if (value < 1e3) return "$-" + value;
                              if (value >= 1e3 && value < 1e6) return "$-" + (value / 1e3).toFixed(1) + "K";
                              if (value >= 1e6 && value < 1e9) return "$-" + (value / 1e6).toFixed(1) + "M";
                              if (value >= 1e9 && value < 1e12) return "$-" + (value / 1e9).toFixed(1) + "B";
                              if (value >= 1e12) return "$-" + (value / 1e12).toFixed(1) + "T";
                            }
                            if (value < 1e3) return "$" + value;
                            if (value >= 1e3 && value < 1e6) return "$" + (value / 1e3).toFixed(1) + "K";
                            if (value >= 1e6 && value < 1e9) return "$" + (value / 1e6).toFixed(1) + "M";
                            if (value >= 1e9 && value < 1e12) return "$" + (value / 1e9).toFixed(1) + "B";
                            if (value >= 1e12) return "$" + (value / 1e12).toFixed(1) + "T";
                        }
                    }
                }
            },
        }
      }
  },
}
</script>