<template>
<b-row>
    <b-col cols="6" class="pr-0">
        <div class="chart-container">
            <donut-chart
                ref="donutChart"
                :chartData="chartData"
                :hideLegend="true"
                :cutoutPercent="55"
                :loaded="!dataLoading"
                :styles="{'width': '100%', 'height': '100%', 'position': 'relative'}"
            />
            <div v-if="!dataLoading && chartData" class="absolute-centering">
                <div class="d-flex flex-column align-items-center justify-content-center">
                    <div style="color:#38455D;font-size:20px;font-weight:500">{{formatter(_totalVisibleValue)}}</div>
                    <div class="small-head-txt">Total</div>
                </div>
            </div>
        </div>
    </b-col>
    <b-col cols="6">
    <b-overlay :show="dataLoading">
        <div v-if="!dataLoading && (zipLabelAndData != null)" class="d-flex flex-column justify-content-center h-100 pr-5 ml-5">
            <div v-for="(datum, index) in zipLabelAndData" class="d-flex flex-column mb-3" :key="index">
                <div @click="toggleItem(index)" 
                    class="d-flex justify-content-between mb-2"
                    :class="{
                        'disabled-item': itemHiddenStates[index],
                        'clickable': toggleEnabled,
                    }">
                    <div class="small-head-txt">{{datum[0]}}</div>
                    <div class="small-head-txt-bold">{{formatter(datum[1])}}</div>
                </div>
                <div style="width:100%">
                    <div class="thin-bar-background"></div>
                    <div class="thin-bar-overlay" :style="getBarStyle(datum)"></div>
                </div>
            </div>
        </div>
    </b-overlay>
    </b-col>
</b-row>
</template>

<script>
import DonutChart from './DonutChart.vue';
import { formatDollarsNoCents as formatDollars, formatNumber } from '../../common';


export default {
    name: 'DonutWithBar',
    components: {
        DonutChart
    },
    props: {
        dataLoading: {
            type: Boolean,
            default: false
        },
        chartData: {
            type: Object,
            required: false,
            default: null,
        },
        totalValue: {
            type: Number,
            required: false,
        },
        format: {
            type: String,
            default: 'number',
        },
        toggleEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            itemHiddenStates: [],
        }
    },
    computed: {
        dataset: function() {
            if (!this.chartData) {
                return null;
            }
            return this.chartData.datasets[0];
        },
        otherDataset: function() {
            if (!this.chartData || this.chartData.datasets.length < 2) {
                return null;
            }
            return this.chartData.datasets[1];
        },
        labels: function() {
            if (this.chartData.labels) {
                return this.chartData.labels;
            }
            else {
                return this.dataset.label;
            }
        },
        zipLabelAndData: function() {
            if (!this.chartData) {
                return [];
            }
            const zip = (a, b, c) => a.map((x, i) => [x, b[i], c[i]]);
            return zip(this.labels, 
                        this.dataset.data, 
                        this.dataset.backgroundColor,
                        this.itemHiddenStates
            );
        },
        formatter: function() {
            if (this.format === 'dollars') {
                return formatDollars;
            } else if (this.format === 'number') {
                return formatNumber;
            } else {
                return formatNumber;
            }
        },
        _totalValue: function() {
            if (this.totalValue) {
                return this.totalValue;
            } else if (this.chartData) {
                return this.dataset.data.reduce((a, b) => a + b, 0);
            } else {
                return 0; 
            }
        },
        _totalVisibleValue: function() {
            if (this.chartData) {
                // add up all the items whose index is not in the itemHiddenStates array
                return this.dataset.data.reduce((acc, val, index) => {
                    if (!this.itemHiddenStates[index]) {
                        return acc + val;
                    } else {
                        return acc;
                    }
                }, 0);
            } else {
                return 0;
            }
        }
    },
    methods: {
        getChartInstance: function() {
            return this.$refs.donutChart.$refs.chart.getCurrentChart();
        },
        getBarStyle(datum){
            var styleObject = {
                backgroundColor: 'transparent',
                width: '0px'
            }
            var rangeAmount = 0;
            rangeAmount = datum[1];
            styleObject.backgroundColor = datum[2];
            if(rangeAmount != 0) {
                var percent = ((rangeAmount/this._totalValue) * 100).toFixed(0);
                styleObject.width = percent + '%';
            }
            return styleObject;
        },
        toggleItem: function(index) {
            if (!this.toggleEnabled) {
                return;
            }
            const ci = this.getChartInstance();
            let op;
            if (this.itemHiddenStates[index]) {
                op = 'show';
            } else {
                op = 'hide';
            }
            ci[op](0, index);
            if (this.otherDataset) {
                this.otherDataset.groups.forEach(
                    (group, i) => {
                        if (group == index) {
                            ci[op](1, i);
                        }
                    }
                );
            }
            ci.update();
            this.$set(this.itemHiddenStates, index, !this.itemHiddenStates[index]);
        },
    }
}
</script>

<style scoped>
.small-head-txt {
    font-size:14px;
    color:#38455D;
}
.disabled-item {
    text-decoration: line-through;
    color: var(--gray);
}
.clickable {
    cursor: pointer;
}
.chart-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
    overflow:visible !important;
}
.absolute-centering {
    position: absolute;
    /* move the element half way across the screen */
    left: 50%;
    /* allow the width to be calculated dynamically */
    width: auto;
    /* then move the element back again using a transform */
    transform: translateX(-50%);
    z-index: 1;
}

/* styles for custom AR By Age progress bar-type graph */
.thin-bar-background {
    background-color:#E7EAEE;
    height:7px;
    border-radius:4px;
}
.thin-bar-overlay {
    height:7px;
    border-radius:4px;
    margin-top:-7px;
}
</style>