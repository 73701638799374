<template>
  <div class="chart-container">
    <Doughnut
      ref="chart"
      v-if="loaded && chartData"
      :chart-data="chartData"
      :chart-options="options"
      :styles="styles"
    />
  </div>
</template>

<script>
import "chart.js/auto";
import { Doughnut } from "vue-chartjs/legacy";

export default {
  name: "DonutChart",
  components: { Doughnut },

  // Pass testMode='true' prop if using hardcoded data
  props: [
    "chartData",
    "hideLegend",
    "cutoutPercent",
    "showPercentLabels",
    "hideToolTips",
    "testMode",
    "loaded",
    "styles",
  ],
  computed: {
    options() {
      var opts = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {}
          }
        },
      };
      if (this.cutoutPercent) {
        opts["cutoutPercentage"] = this.cutoutPercent;
      }
      if (this.hideLegend) {
        opts["plugins"]["legend"] = { display: false };
      } else {
        opts["plugins"]["legend"] = { display: true, position: "right" };
      }
      opts["plugins"]["tooltip"]["callbacks"] = {
        label: function(context) {
          if (context.dataset.label instanceof Array) {
            return `${context.dataset.label[context.dataIndex]}: ${context.formattedValue}`;
          } else {
            return `${context.label}: ${context.formattedValue}`;
          }
        }
      }
      if (this.hideToolTips) {
        opts["plugins"]["tooltip"] = { enabled: false };
      }
      return opts;
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>